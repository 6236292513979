
import React from 'react';
import {  Button, Card,  Container } from 'react-bootstrap';
import APIClient from '../inc/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let api = new APIClient();

interface Props {}
interface State {}

class Home extends React.Component <Props, State> {

  render(){
    return <>
    <Container fluid>
      <Card>
          <Card.Header><FontAwesomeIcon icon="house" />&nbsp; Home</Card.Header>
          <Card.Body>

            <h4>Boe</h4>

            <p>
              {JSON.stringify(api.user)}
            </p>

            {/* logout button pls */}
            <Button variant="primary" onClick={api.logout}>Logout</Button>
          </Card.Body>
      </Card>

    </Container>





    </>

  }

}


export default Home
