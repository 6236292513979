
import React from 'react';
import { useParams } from 'react-router-dom';

// this is a helper function to pass props & params to class component from router
// usage: export default withRouterParams(App);
// export with router params when they are needed in the component. /whatever/:id
export function withRouterParams(Component:any) {
  return (props?: any) => <Component {...props} params={useParams()} />;
}

export function capitalize(s:string){
    return s[0].toUpperCase() + s.slice(1);
}


export function dayName(date:Date){
  let days = ["Zondag","Maandag","Dinsdag","Woensdag","Donderdag","Vrijdag","Zaterdag"];
  return days[date.getDay()];
}

// function to get the date of the first day of the week
export function getWeekStart(date:Date){
  let day = date.getDay() || 7;
  if( day !== 1 )
      date.setHours(-24 * (day - 1));
  return date;
}

// function to list dates of the week
export function getWeekDates(date?:Date){
  if(!date) date = new Date();
  let week = [];
  let start = getWeekStart(date);
  for (let i = 0; i < 7; i++) {
    let d = new Date(start);
    d.setDate(d.getDate() + i);
    week.push(d);
  }
  return week;
}


// minutes to time str
export function minutesToTimeStr(minutes:any){

  minutes = parseInt(minutes);

  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;

  // pad with 0
  let hoursStr = hours.toString().padStart(2, "0");
  let minsStr = mins.toString().padStart(2, "0");

  return `${hoursStr}:${minsStr}`;
}

// time str to minutes
export function timeStrToMinutes(timeStr:string){
  let parts = timeStr.split(":");
  let hours = parseInt(parts[0]);
  let mins = parseInt(parts[1]);

  return hours * 60 + mins;
}


// current week nuber
export function getWeekNumber(date?:Date) {
  if(!date) date = new Date();
  // Copy date so don't modify original
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
  // Get first day of year
  let yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  let weekNo = Math.ceil(( ( (date.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  return weekNo;
}


// date format
export function dateFormat(date:Date | string, format:string = "d-m-Y"){
  if(typeof date == "string"){
    date = new Date(date);
  }
  let day:any = date.getDate();
  let month:any = date.getMonth() + 1;
  let year = date.getFullYear();

  // pad values
  day = day.toString().padStart(2, "0");
  month = month.toString().padStart(2, "0");

  if(format == "Y-m-d") return `${year}-${month}-${day}`;


  return `${day}-${month}-${year}`;
}



export function tabChange (tab:string ){
  sessionStorage.setItem("activeTab", tab);

  let event = new Event('storage')
  window.dispatchEvent(event);
}
