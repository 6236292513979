import ReactDOM from 'react-dom/client';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Hello. Nothing much happens here besides importing style and adding the root component to the DOM.
// /public/index.html is the entry point for the app.
// App.tsx is the entry point for the React app.

// when running npm audit. remember to run npm audit --production
// https://github.com/facebook/create-react-app/issues/11174

// bootstrap css -> can be replaced with any other 5.x version. (see react bootstrap node module)
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './router';

// add font awesome iconset
// https://fontawesome.com/icons
// only use the free icons
library.add(fas, fab, far);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render( <App /> );

