
import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import { withRouterParams } from '../../inc/helpers';
import { GameHome } from './home';


interface Parameters { }
interface Props { params?: Parameters; }
interface State { }

class GameRouter extends React.Component <Props, State> {

    render(){
        return  <>
          <Routes>

            <Route path="/" element={<GameHome />}> </Route>
            {/* <Route path="/stats/:id" element={<Test />}> </Route> */}
          </Routes>
        </>
    }
}


export default withRouterParams(GameRouter);
