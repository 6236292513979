import axios, { AxiosInstance } from "axios";
import { Appointment, Attendance, Group, User } from "./types";

// API Client -> copied from api except for constructor / logout.
// No need to check if a user is still logged in. if a request fails, the user will be logged out.
// see how axios instance is setup for more info.
export default class APIClient {

    activeGroup: number; // what group are we viewing in the admin ?
    user: User | null = null;
    axios: AxiosInstance;

    constructor() {

      let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
      this.activeGroup = localStorage.getItem('activeGroup') ? parseInt(localStorage.getItem('activeGroup') || "1") : 1;


        if (token && token !== "") { // if token is provided, add it to the headers of the axios instance

            this.axios = axios.create({ // used for all requests that require authentication
                baseURL: `${process.env.REACT_APP_API_URL}` || 'http://localhost:3001',
                timeout: 1000,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // interceptors - log out user on 4xx errors
            this.axios.interceptors.response.use((response) => { return response; }, (error) => {

                // logout on 401
                if (error.response.status === 401) { this.logout(); }

                // kick user out on every 400 error
                if(error.response.status > 400 && error.response.status < 500) { this.logout(); }

                return error;
            });

            // set user object from token payload
            this.user = JSON.parse(atob(token.split('.')[1]).toString()); // need a alternative to atob but it works for now.

        }else{
            this.axios = axios.create({ // only used for login
                baseURL: `${process.env.REACT_APP_API_URL}` || 'http://localhost:3001',
                timeout: 1000
            });

            if(!window.location.href.includes('/login')){
                window.location.href = '/login';
            }
        }
    }

    logout() {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }


    setActiveGroup(id: any) {
      this.activeGroup = id;
      localStorage.setItem('activeGroup', id.toString());
      console.log("active group: " + this.activeGroup)
    }


    async UserCreate(user: Partial<User>) {
        return await this.axios.post('/users/create', user).then(response => { return response.data })
    }

    async UserLogin(user: Partial<User>) { // expects email and password
        return await this.axios.post('/users/login', user).then(response => { return response.data })
    }

    async UserGet(id: number) {
        return await this.axios.get(`/users/id/${id}`).then(response => { return response.data })
    }

    async UserUpdate( user: Partial<User>) { // expects id in the user object.
        return await this.axios.put(`/users/update`, user).then(response => { return response.data })
    }

    async UserDelete(id: number) { // expects user id
        return await this.axios.delete(`/users/delete/${id}`).then(response => { return response.data })
    }

    async UserGetAll() { // expects nothing // temporary
        return await this.axios.get('/users').then(response => { return response.data })
    }

    // TODO users by group.

    async UserGetByEmail(email: string) { // expects email
        return await this.axios.get(`/users/email/${email}`).then(response => { return response.data })
    }

    async UserCurrent() { // expects nothing -- returns token payload / user object
        return await this.axios.get('/users/current').then(response => { return response.data })
    }


    // groups
    async GroupCreate(group: Partial<Group>) { // expects Group object w/o id
        return await this.axios.post('/groups/create', group).then(response => { return response.data })
    }

    async GroupGet(id: number) { // expects id
        return await this.axios.get(`/groups/id/${id}`).then(response => { return response.data })
    }

    async GroupGetAll() { // expects nothing
        return await this.axios.get('/groups').then(response => { return response.data })
    }

    async GroupUpdate(group: Partial<Group>) { // expects id in group object.
        return await this.axios.put(`/groups/update`, group).then(response => { return response.data })
    }

    async GroupDelete(id: number) { // expects group id
        return await this.axios.delete(`/groups/delete/${id}`).then(response => { return response.data })
    }


    // appointments
    async AppointmentCreate(appointment: Appointment | any) { // expects title, group_id, user_id, start_date, start, stop, type, meta
        return await this.axios.post('/appointment/create', appointment).then(response => { return response.data })
    }

    async AppointmentGet(id: number) { // expects id
        return await this.axios.get(`/appointment/${id}`).then(response => { return response.data })
    }

    async AppointmentUpdate(appointment: Appointment | any) { // expects id in appointment object
        return await this.axios.put(`/appointment/update`, appointment).then(response => { return response.data })
    }

    async AppointmentDelete(id: number) { // expects appointment id
        return await this.axios.delete(`/appointment/delete/${id}`).then(response => { return response.data })
    }

    async AppointmentGetGroup(group_id: number) { // expects group id
        return await this.axios.get('/appointment/group/' + group_id).then(response => { return response.data })
    }

    async AppointmentGetUser(user_id: number) { // expects user id
        return await this.axios.get('/appointment/user/' + user_id).then(response => { return response.data })
    }

    async AppointmentSearch(dateStart: string, dateStop:string, users: Array<number> = [], groups: Array<number> = [] ) {
        return await this.axios.post('/appointment/search', { start:dateStart, stop:dateStop, users, groups }).then(response => { return response.data })
    }

    // attendances
    async AttendanceCreate(attendance: Partial<Attendance>) { // expects title, group_id, user_id, start_date, start, stop, type, meta
        return await this.axios.post('/attendance/create', attendance).then(response => { return response.data })
    }

    async AttendanceGet(id: number) { // expects attendance id
        return await this.axios.get(`/attendance/${id}`).then(response => { return response.data })
    }

    async AttendanceGetByAppointment(appointment_id: number) {
        return await this.axios.get(`/attendance/appointment/${appointment_id}`).then(response => { return response.data })
    }

    async AttendanceUpdate(attendance: Attendance) { // expects id in attendance object
        return await this.axios.put(`/attendance/update`, attendance).then(response => { return response.data })
    }

    async AttendanceDelete(id: number) { // expects attendance id
        return await this.axios.delete(`/attendance/delete/${id}`).then(response => { return response.data })
    }

    async AttendanceGetGroup(group_id: number) { // expects group id
        return await this.axios.get('/attendance/group/' + group_id).then(response => { return response.data })
    }

    async AttendanceGetUser(user_id: number) { // expects user id
        return await this.axios.get('/attendance/user/' + user_id).then(response => { return response.data })
    }


    async AttendanceSearch(dateStart: string, dateStop:string, users: Array<number> = [], groups: Array<number> = [] ) {
        return await this.axios.post('/attendance/search', { start:dateStart, stop:dateStop, users, groups }).then(response => { return response.data })
    }


}
