
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withRouterParams } from './inc/helpers';
import Home from './pages/home';
import Login from './pages/login';
import AMPLayout from './layout/amplayout';
import GameRouter from './pages/game/router';

interface Parameters { }
interface Props { params?: Parameters; }
interface State { }

class App extends React.Component <Props, State> {
    render(){
        return <React.StrictMode>
          <BrowserRouter>
          <AMPLayout>
                <Routes>

                  {/* every page redirects here if there is no token */}
                  <Route path="/login" element={<Login />}> </Route>

                  {/* homepage/dashboard  */}
                  <Route path="/" element={<Home />}> </Route>

                  {/* Game module -> sub router example */}
                  <Route path="/game/*" element={<GameRouter />}> </Route>

                  {/* 404 */}
                  <Route path="*" element={<><h1>404</h1></>}> </Route>

                </Routes>
                </AMPLayout>
          </BrowserRouter>
        </React.StrictMode>
    }
}


export default withRouterParams(App);
