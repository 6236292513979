
import React from 'react';
import {  Breadcrumb, Card,  Col,  Row } from 'react-bootstrap';
import APIClient from '../../inc/APIClient';
import { withRouterParams } from '../../inc/helpers';

import { Unity, useUnityContext } from "react-unity-webgl";

interface Parameters {}
interface Props { params?: Parameters; }
interface State {}

   function UnityComp() {
        const { unityProvider } = useUnityContext({
            loaderUrl: "build/myunityapp.loader.js",
            dataUrl: "build/myunityapp.data",
            frameworkUrl: "build/myunityapp.framework.js",
            codeUrl: "build/myunityapp.wasm",
        });

        return (
            <Unity unityProvider={unityProvider} style={{ width: 800, height: 600 }} />
        );
  }

export class GameHome extends React.Component <Props, State> {
    api: APIClient;

    constructor(props: Props){
        super(props);
        this.api = new APIClient();
        this.state = {};
    }

    render(){


        return <>
        <Row>
            <Col md={10}>
              <Breadcrumb >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Game</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
        </Row>
        <hr/>
        <Card>
            <Card.Body>
                {/* <UnityComp/> */}
            </Card.Body>
        </Card>

        </>


    }
}


export default withRouterParams(GameHome);
